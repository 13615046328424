import { call, put } from "redux-saga/effects";
import { API_URL, ACTIONS } from "../_config";
import { CallApi } from "./api/callApi";

export function* checkLeagueCode(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.LEAGUE.CHECK_LEAGUE_CODE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* createLeagueCode(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.LEAGUE.CREATE_LEAGUE_CODE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getLeagueDetailsByID(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.LEAGUE.LEAGUE_BY_LEAGUEID + data.leagueID);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.LEAGUE.LOADEDLEAGUE,
        payload: resp.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* callMyLeagueList(action: any): any {
  try {
    const gameID = action.payload;
    const resp = yield call(
      CallApi.GET,
      gameID ? API_URL.LEAGUE.MY_LEAGUE_LIST + gameID : API_URL.LEAGUE.MY_LEAGUE_LIST
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.LEAGUE.MY_LEAGUE_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getTeamLeagueList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.LEAGUE.TEAM_LIST + data.leagueCode);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
export function* deleteLeague(action: any): any {
  try {
    const leagueID = action.payload;
    const resp = yield call(CallApi.DELETE, API_URL.LEAGUE.DELETE_LEAGUE + leagueID, leagueID, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
