import { SAGA_ACTIONS } from 'src/_config'
import { GetCmsContent } from 'src/_common/interfaces/ApiReqRes'
import { useApiCall } from '../common/appApiCallHook'



export function useCmsApi() {

    const callApi = useApiCall()

    const getCmsContent = (data: GetCmsContent, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CMS.GET_CMS, data, onSuccess, onError);
    }

    return {
        callGetCmsContent: getCmsContent,
    }
}