import { API_BASE_URL, AI_BASE_URL } from "./site_urls";

export const APPLICATION_NAME = "Pro Fantasy Rodeo";

export const STORAGE = "ProfantasyRodeo";
export const SELECTED_EVENT_STORAGE = `${APPLICATION_NAME}-Event`;
export const TESTPASSWORD = "teton$2024";
export const PAGE_TITLE = `${APPLICATION_NAME} - `;
export const FILE_BASE_PATH = "";
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_STAGE = process.env.REACT_APP_STAGE;

export const DATE_FORMAT = "MM/dd/yyyy";

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: "MM/dd/yyyy",
  MOMENT_FORMAT: "MM/DD/yyyy",
};

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: "Do MMMM, YYYY hh:mm a",
  DISPLAY_DAY_TIME: "dddd hh:mm a",
};
export const TIME_CONFIG = {
  TIME_FORMAT: "hh:mm a",
  TIME_INTERVALS: 5,
};
export const DEFAULT_LATITUDE = "31.9686";
export const DEFAULT_LONGITUDE = "-99.9018";

export const URLS = {
  LANDING: "/",
  LANDINGSURVEY: "/2024-nfr-prizes",
  LOGIN: "/login",
  AUTOLOGIN: "/autologin/:userId/:phone",
  MYTEAM: "/dashboard",
  MYACCOUNT: "/my-account",
  GAMEDASHBOARD: "/games",
  GAMERESULTS: "/game/:gameSlug",
  CREATETEAM: "/build-team/:leagueID?",
  CREATETEAMLNK: "/build-team",
  CARTPAGE: "/my-cart",
  FANMAGAZINE: "/fanmagazine",
  NEWSDETAILS: "/news/:slug",
  WINNERSTORIES: "/winner-stories-list",
  WINNERSTORY_DETAILS: "/winner-story-details/:slug",
  RIDERS: "/riders",
  CMS: "/cms/:slug/:gameId?",
  CONTACTUS: "/contact-us",
  LEAGUEDETAILS: "/league-details/:gameSlug",
  LEAGUECREATE: "/create-league/:gameSlug",
  SEARCHTEAM: "/search-team",
  EDITTEAM: "/edit-team/:teamID",
  TEAMDETAILS: "/team/details/:teamID",
  RODEORESULTS: "/rodeo-results/:gameSlug",
  ROUNDWISERESULTS: "/round-wise-team/:gameSlug",
  CURCUITRESULTS: "/circuit-team/:gameSlug/:curcuitId",
  LEAGUERESULTS: "/league-score-details/:leagueId",
  PRIZERESULTS: "/prize-score-details/:gameSlug/:prizeId",
};

export const API_URL = {
  // API URL
  USER: {
    LOGIN: `${API_BASE_URL}login/login`,
    LOGIN_WITH_OTP: `${API_BASE_URL}login/login-with-otp`,
    LOGOUT: `${API_BASE_URL}auth/logout`,
    DETAILS: `${API_BASE_URL}init`,
    UPDATE_PROFILE: `${API_BASE_URL}users/update-user`,
    LOGIN_WITH_USERNAME: `${API_BASE_URL}login/login-with-username`,
    FORGOT_PASSWORD: `${API_BASE_URL}login/forgot-password`,
    DELETE_USER: `${API_BASE_URL}users/delete-user`,
    AUTOLOGIN: `${API_BASE_URL}login/autologin`,
  },
  GAME: {
    GET_GAME_LIST: `${API_BASE_URL}games/list/`,
    ENTRY_OPEN_NOTIFICATION: `${API_BASE_URL}games/entry-open-notification`,
    UPCOMING_GAMES: `${API_BASE_URL}games/upcoming-games`,
    GAMEDETAILSBYID: `${API_BASE_URL}games/details/`,
    CHECKGAMESTATUS: `${API_BASE_URL}games/check-status/`,
    CHECKTRANOFFCODE: `${API_BASE_URL}games/check-free-transaction-fees`,
  },
  TEAM: {
    LOAD_DRAFT_TEAM_DATA: `${API_BASE_URL}teams/get-temp-saved-data`,
    SAVE_DRAFT_TEAM_DATA: `${API_BASE_URL}teams/save-temp-data`,
    GET_EVENT_LIST: `${API_BASE_URL}teams/get-events`,
    GET_INSURANCE_LIST: `${API_BASE_URL}games/get-insurances/`,
    GET_PRIZE_LIST: `${API_BASE_URL}games/get-prizes/`,
    GET_FRONTEND_LEAGUE: `${API_BASE_URL}games/get-frontend-charity-league/`,
    CHECK_PROMO_CODE: `${API_BASE_URL}promocode/check-code`,
    GET_CONTESTANT_AI: `${API_BASE_URL}ai/gamewise-event-contestant-lists/`,
    SEARCH_CONTESTANT_AI: `${API_BASE_URL}ai/contestant-search`,
    CHECK_CONTESTANT_AI: `${API_BASE_URL}ai/check-contestant`,
    MY_TEAM_LIST: `${API_BASE_URL}teams/get-team/`,
    SEARCH_TEAM: `${API_BASE_URL}teams/search`,
    DELETE_TEAM: `${API_BASE_URL}teams/delete-team/`,
    LOAD_TEAM_DETAILS: `${API_BASE_URL}teams/details/`,
    UPDATE_TEAM: `${API_BASE_URL}teams/update/`,
    GET_PERFORMANCE_LIST: `${API_BASE_URL}games/get-performances/`,
    GET_INSURANCE_APPLICABLE: `${API_BASE_URL}teams/enable-insurance`,
    INSURANCE_APPLY: `${API_BASE_URL}teams/apply-insurance`,
    REVERT_INSURANCE: `${API_BASE_URL}teams/cowboy-revert-back`,
    CART_ITEMS: `${API_BASE_URL}teams/get-cart-team/`,
  },
  LEAGUE: {
    CHECK_LEAGUE_CODE: `${API_BASE_URL}leagues/check-code`,
    CREATE_LEAGUE_CODE: `${API_BASE_URL}leagues/create`,
    LEAGUE_BY_LEAGUEID: `${API_BASE_URL}leagues/check-valid-league/`,
    MY_LEAGUE_LIST: `${API_BASE_URL}leagues/my-league/`,
    TEAM_LIST: `${API_BASE_URL}leagues/team-list-code/`,
    DELETE_LEAGUE: `${API_BASE_URL}leagues/delete-league/`,
  },
  SCORE: {
    GET_SCORE_DETAILS: `${API_BASE_URL}teams/get-team-score/`,
    GET_PICK_BBR_SCORE_DETAILS: `${API_BASE_URL}teams/get-pick-type-team-score/`,
    GET_RANK_DETAILS: `${API_BASE_URL}teams/get-team-rank/`,
    GET_GAME_SCORE: `${API_BASE_URL}score/rodeo-home-result/`,
    GET_RODEO_RESULTS: `${API_BASE_URL}score/rodeo-result/`,
    ROUNDWISERESULTS: `${API_BASE_URL}score/rodeo-round-wise-top-result/`,
    CURCUITRESULTS: `${API_BASE_URL}score/circuit-result/`,
    LATESTSTANDINGS: `${API_BASE_URL}score/latest-standings`,
    LEAGUEOVERALLSCORE: `${API_BASE_URL}score/league-result/`,
    LEAGUEROUNDSCORE: `${API_BASE_URL}score/league-result-round-wise/`,
    PRIZEOVERALLSCORE: `${API_BASE_URL}score/prize-result/`,
    PRIZEROUNDSCORE: `${API_BASE_URL}score/prize-rslt-round-wise/`,
    TOP_HEADER_SCORE_BAR: `${API_BASE_URL}score/top-game-round-wise-result`,
  },
  CMS: {
    GET_CMS: `${API_BASE_URL}common/get-cms`,
  },
  COMMON: {
    COUNTRY_LIST: `${API_BASE_URL}country/list`,
    MENU_ITEMS: `${API_BASE_URL}common/get-menus`,
    SETTINGS: `${API_BASE_URL}common/get-settings`,
    SPONSOR: `${API_BASE_URL}common/get-sponsors`,
    STATUS_MESSAGE: `${API_BASE_URL}common/get-status`,
    GALLERY: `${API_BASE_URL}common/get-galleries`,
    COUNTRIES: `${API_BASE_URL}common/get-countries`,
    STATES: `${API_BASE_URL}common/get-states`,
    CITIES: `${API_BASE_URL}common/get-cities`,
    RIDERS: `${API_BASE_URL}common/get-riders`,
    TESTIMONIALS: `${API_BASE_URL}common/get-testimonials`,
    BLOCKS: `${API_BASE_URL}common/get-blocks/`,
    WINNERSTORY: `${API_BASE_URL}common/winner-story-list/`,
    CONTACTUS: `${API_BASE_URL}common/contact-us`,
    SEO_LIST: `${API_BASE_URL}common/get-seo-details`,
  },
  NEWSLETTER: {
    SIGNUP: `${API_BASE_URL}newsletter/signup`,
  },
  NEWS: {
    LIST: `${API_BASE_URL}news/list`,
    DETAILS: `${API_BASE_URL}news/details`,
  },
  PAYMENT: {
    GET_CARD_LIST: `${API_BASE_URL}payments/card`,
    DELETE_CARD: `${API_BASE_URL}payments/delete-card`,
    SET_DEFAULT_CARD: `${API_BASE_URL}payments/set-default-card`,
    MAKE_PAYMENT: `${API_BASE_URL}payments/pay`,
    MAKE_BULK_PAYMENT: `${API_BASE_URL}payments/pay-cart`,
  },
};

export const ACTIONS = {
  // STORE Data in Redux
  LOADER: {
    SET_FP_STATUS: "ACTIONS/LOADER/SET_FP_STATUS",
  },
  GLOBAL: {
    SET_LOGIN_MODAL: "ACTIONS/GLOBAL/SET_LOGIN_MODAL",
  },
  USER: {
    LOGIN: "ACTIONS/USER/LOGIN",
    LOGIN_WITH_OTP: "ACTIONS/USER/LOGIN_WITH_OTP",
    LOGIN_WITH_USERNAME: "ACTIONS/USER/LOGIN_WITH_USERNAME",
    ME: "ACTIONS/USER/ME",
    LOGOUT: "ACTIONS/USER/LOGOUT",
    FORGOT_PASSWORD: "ACTIONS/USER/FORGOT_PASSWORD",
  },
  GAME: {
    GET_GAME_LIST: "ACTIONS/GAME/GET_GAME_LIST",
    CLEAR_GAME_LIST: "ACTIONS/TEAM/CLEAR_GAME_LIST",
  },
  TEAM: {
    LOAD_DRAFT_TEAM_DATA: "ACTIONS/TEAM/LOAD_DRAFT_TEAM_DATA",
    CLEAR_DRAFT_TEAM_DATA: "ACTIONS/TEAM/CLEAR_DRAFT_TEAM_DATA",
    GET_EVENT_LIST: "ACTIONS/TEAM/GET_EVENT_LIST",
    GET_INSURANCE_LIST: "ACTIONS/TEAM/GET_INSURANCE_LIST",
    GET_PRIZE_LIST: "ACTIONS/TEAM/GET_PRIZE_LIST",
    GET_FRONTEND_LEAGUE: "ACTIONS/TEAM/GET_FRONTEND_LEAGUE",
    SAVE_TEMPORARY_DATA: "ACTIONS/TEAM/SAVE_TEMPORARY_DATA",
    GET_CONTESTANT_AI: "ACTIONS/TEAM/GET_CONTESTANT_AI",
    SET_AI_SELECT_LIST: "ACTIONS/TEAM/SET_AI_SELECT_LIST",
    SEARCH_AI_SELECT_LIST: "ACTIONS/TEAM/SEARCH_AI_SELECT_LIST",
    CLEAR_AI: "ACTIONS/TEAM/CLEAR_AI",
    CLEAR_CONST_AI: "ACTIONS/TEAM/CLEAR_CONST_AI",
    UPDATE_CONST_LIST_AI: "ACTIONS/TEAM/UPDATE_CONST_LIST_AI",
    CLEAR_RESET_TEAM: "ACTIONS/TEAM/CLEAR_RESET_TEAM",
    MY_TEAM_LIST: "ACTIONS/TEAM/MY_TEAM_LIST",
    MY_TEAM_CLEAR_LIST: "ACTIONS/TEAM/MY_TEAM_CLEAR_LIST",
    IS_SEARCH_TEAM_PAGE: "ACTIONS/TEAM/IS_SEARCH_TEAM_PAGE",
    LOAD_TEAM_DETAILS: "ACTIONS/TEAM/LOAD_TEAM_DETAILS",
    GET_PERFORMANCE_LIST: "ACTIONS/TEAM/GET_PERFORMANCE_LIST",
    GET_INSURANCE_APPLICABLE: "ACTIONS/TEAM/GET_INSURANCE_APPLICABLE",
    INSURANCE_APPLY: "ACTIONS/TEAM/INSURANCE_APPLY",
    REVERT_INSURANCE: "ACTIONS/TEAM/REVERT_INSURANCE",
    CLEAR_TEAMDATA: "ACTIONS/TEAM/CLEAR_TEAMDATA",
    CART_ITEMS: "ACTIONS/TEAM/CART_ITEMS",
  },
  LEAGUE: {
    MY_LEAGUE_LIST: "ACTIONS/LEAGUE/MY_LEAGUE_LIST",
    LOADEDLEAGUE: "ACTIONS/LEAGUE/LOADEDLEAGUE",
    CLEARLOADEDLEAGUE: "ACTIONS/LEAGUE/CLEARLOADEDLEAGUE",
  },
  SCORE: {
    GET_SCORE_DETAILS: "ACTIONS/SCORE/GET_SCORE_DETAILS",
    GET_RANK_DETAILS: "ACTIONS/SCORE/GET_RANK",
    GET_GAME_SCORE: "ACTIONS/SCORE/GET_GAME_SCORE",
    GET_RODEO_RESULTS: "ACTIONS/SCORE/GET_RODEO_RESULTS",
    ROUNDWISERESULTS: "ACTIONS/SCORE/ROUNDWISERESULTS",
    CURCUITRESULTS: "ACTIONS/SCORE/CURCUITRESULTS",
    LATESTSTANDINGS: "ACTIONS/SCORE/LATESTSTANDINGS",
    LEAGUEOVERALLSCORE: "ACTIONS/SCORE/LEAGUEOVERALLSCORE",
    LEAGUEROUNDSCORE: "ACTIONS/SCORE/LEAGUEROUNDSCORE",
    PRIZEOVERALLSCORE: "ACTIONS/SCORE/PRIZEOVERALLSCORE",
    PRIZEROUNDSCORE: "ACTIONS/SCORE/PRIZEROUNDSCORE",
    TOPHEADERSCORE: "ACTIONS/SCORE/TOPHEADERSCORE",
    CLEAR_SCORE: "ACTIONS/TEAM/CLEAR_SCORE",
  },
  COMMON: {
    MENU_LIST: "ACTIONS/COMMON/MENU_LIST",
    SETTINGS: "ACTIONS/COMMON/SETTINGS",
    SPONSOR: "ACTIONS/COMMON/SPONSOR",
    BLOCKS: "ACTIONS/COMMON/BLOCKS",
    TEAM_CREATE_PROCESS_STATUS: "ACTIONS/COMMON/TEAM_CREATE_PROCESS_STATUS",
    SEO_LIST: "ACTIONS/COMMON/SEO_LIST",
    REFFERER_URL: "ACTIONS/COMMON/REFFERER_URL",
  },
};

export const SAGA_ACTIONS = {
  // GET Store data from Redux
  USER: {
    LOGIN: "SAGA_ACTIONS/USER/LOGIN",
    LOGIN_WITH_OTP: "SAGA_ACTIONS/USER/LOGIN_WITH_OTP",
    LOGOUT: "SAGA_ACTIONS/USER/LOGOUT",
    DETAILS: "SAGA_ACTIONS/USER/DETAILS",
    UPDATE_PROFILE: "SAGA_ACTIONS/USER/UPDATE_PROFILE",
    LOGIN_WITH_USERNAME: "SAGA_ACTIONS/USER/LOGIN_WITH_USERNAME",
    FORGOT_PASSWORD: "SAGA_ACTIONS/USER/FORGOT_PASSWORD",
    DELETE_USER: "SAGA_ACTIONS/USER/DELETE_USER",
    AUTOLOGIN: "SAGA_ACTIONS/COMMON/AUTOLOGIN",
  },
  GAME: {
    GET_GAME_LIST: "SAGA_ACTIONS/GAME/GET_GAME_LIST",
    ENTRY_OPEN_NOTIFICATION: "SAGA_ACTIONS/GAME/ENTRY_OPEN_NOTIFICATION",
    UPCOMING_GAMES: "SAGA_ACTIONS/GAME/UPCOMING_GAMES",
    GAMEDETAILSBYID: "SAGA_ACTIONS/SCORE/GAMEDETAILSBYID",
    CHECKGAMESTATUS: "SAGA_ACTIONS/GAME/CHECKGAMESTATUS",
    CHECKTRANOFFCODE: "SAGA_ACTIONS/GAME/CHECKTRANOFFCODE",
  },
  TEAM: {
    LOAD_DRAFT_TEAM_DATA: "SAGA_ACTIONS/TEAM/LOAD_DRAFT_TEAM_DATA",
    SAVE_DRAFT_TEAM_DATA: "SAGA_ACTIONS/TEAM/SAVE_DRAFT_TEAM_DATA",
    GET_EVENT_LIST: "SAGA_ACTIONS/TEAM/GET_EVENT_LIST",
    GET_INSURANCE_LIST: "SAGA_ACTIONS/TEAM/GET_INSURANCE_LIST",
    GET_PRIZE_LIST: "SAGA_ACTIONS/TEAM/GET_PRIZE_LIST",
    GET_FRONTEND_LEAGUE: "SAGA_ACTIONS/TEAM/GET_FRONTEND_LEAGUE",
    CHECK_PROMO_CODE: "SAGA_ACTIONS/TEAM/CHECK_PROMO_CODE",
    GET_CONTESTANT_AI: "SAGA_ACTIONS/TEAM/GET_CONTESTANT_AI",
    SEARCH_AI_SELECT_LIST: "SAGA_ACTIONS/TEAM/SET_AI_SELECT_LIST",
    CHECK_CONTESTANT_AI: "SAGA_ACTIONS/TEAM/CHECK_CONTESTANT_AI",
    MY_TEAM_LIST: "SAGA_ACTIONS/TEAM/MY_TEAM_LIST",
    SEARCH_TEAM: "SAGA_ACTIONS/TEAM/SEARCH_TEAM",
    DELETE_TEAM: "SAGA_ACTIONS/TEAM/DELETE_TEAM",
    LOAD_TEAM_DETAILS: "SAGA_ACTIONS/TEAM/LOAD_TEAM_DETAILS",
    UPDATE_TEAM: "SAGA_ACTIONS/TEAM/UPDATE_TEAM",
    GET_PERFORMANCE_LIST: "SAGA_ACTIONS/TEAM/GET_PERFORMANCE_LIST",
    GET_INSURANCE_APPLICABLE: "SAGA_ACTIONS/TEAM/GET_INSURANCE_APPLICABLE",
    INSURANCE_APPLY: "SAGA_ACTIONS/TEAM/INSURANCE_APPLY",
    REVERT_INSURANCE: "SAGA_ACTIONS/TEAM/REVERT_INSURANCE",
    CART_ITEMS: "SAGA_ACTIONS/TEAM/CART_ITEMS",
  },
  LEAGUE: {
    CHECK_LEAGUE_CODE: "SAGA_ACTIONS/LEAGUE/CHECK_LEAGUE_CODE",
    CREATE_LEAGUE_CODE: "SAGA_ACTIONS/LEAGUE/CREATE_LEAGUE_CODE",
    LEAGUE_BY_LEAGUEID: "SAGA_ACTIONS/LEAGUE/LEAGUE_BY_LEAGUEID",
    MY_LEAGUE_LIST: "SAGA_ACTIONS/TEAM/MY_LEAGUE_LIST",
    TEAM_LIST: "SAGA_ACTIONS/LEAGUE/TEAM_LIST",
    DELETE_LEAGUE: "SAGA_ACTIONS/LEAGUE/DELETE_LEAGUE",
  },
  SCORE: {
    GET_SCORE_DETAILS: "SAGA_ACTIONS/SCORE/GET_SCORE_DETAILS",
    GET_PICK_BBR_SCORE_DETAILS: "SAGA_ACTIONS/SCORE/GET_PICK_BBR_SCORE_DETAILS",
    GET_RANK_DETAILS: "SAGA_ACTIONS/SCORE/GET_RANK",
    GET_GAME_SCORE: "SAGA_ACTIONS/SCORE/GET_GAME_SCORE",
    GET_RODEO_RESULTS: "SAGA_ACTIONS/SCORE/GET_RODEO_RESULTS",
    ROUNDWISERESULTS: "SAGA_ACTIONS/SCORE/ROUNDWISERESULTS",
    CURCUITRESULTS: "SAGA_ACTIONS/SCORE/CURCUITRESULTS",
    LATESTSTANDINGS: "SAGA_ACTIONS/SCORE/LATESTSTANDINGS",
    LEAGUEOVERALLSCORE: "SAGA_ACTIONS/SCORE/LEAGUEOVERALLSCORE",
    LEAGUEROUNDSCORE: "SAGA_ACTIONS/SCORE/LEAGUEROUNDSCORE",
    PRIZEOVERALLSCORE: "SAGA_ACTIONS/SCORE/PRIZEOVERALLSCORE",
    PRIZEROUNDSCORE: "SAGA_ACTIONS/SCORE/PRIZEROUNDSCORE",
    TOPHEADERSCORE: "SAGA_ACTIONS/SCORE/TOPHEADERSCORE",
  },
  CMS: {
    GET_CMS: "SAGA_ACTIONS/COMMON/GET_CMS",
  },
  COMMON: {
    COUNTRY_LIST: "SAGA_ACTIONS/COMMON/COUNTRY_LIST",
    MENU_ITEMS: "SAGA_ACTIONS/COMMON/MENU_ITEMS",
    SETTINGS: "SAGA_ACTIONS/COMMON/SETTINGS",
    SPONSOR: "SAGA_ACTIONS/COMMON/SPONSOR",
    STATUS_MESSAGE: "SAGA_ACTIONS/COMMON/STATUS_MESSAGE",
    GALLERY: "SAGA_ACTIONS/COMMON/GALLERY",
    COUNTRIES: "SAGA_ACTIONS/COMMON/COUNTRIES",
    STATES: "SAGA_ACTIONS/COMMON/STATES",
    CITIES: "SAGA_ACTIONS/COMMON/CITIES",
    RIDERS: "SAGA_ACTIONS/COMMON/RIDERS",
    TESTIMONIALS: "SAGA_ACTIONS/COMMON/TESTIMONIALS",
    BLOCKS: "SAGA_ACTIONS/COMMON/BLOCKS",
    WINNERSTORY: "SAGA_ACTIONS/COMMON/WINNERSTORY",
    CONTACTUS: "SAGA_ACTIONS/COMMON/CONTACTUS",
    SEO_LIST: "SAGA_ACTIONS/COMMON/SEO_LIST",
  },
  NEWSLETTER: {
    SIGNUP: "SAGA_ACTIONS/NEWSLETTER/SIGNUP",
  },
  NEWS: {
    LIST: "SAGA_ACTIONS/NEWS/LIST",
    DETAILS: "SAGA_ACTIONS/NEWS/DETAILS",
  },
  PAYMENT: {
    GET_CARD_LIST: "SAGA_ACTIONS/PAYMENT/GET_CARD_LIST",
    DELETE_CARD: "SAGA_ACTIONS/PAYMENT/DELETE_CARD",
    SET_DEFAULT_CARD: "SAGA_ACTIONS/PAYMENT/SET_DEFAULT_CARD",
    MAKE_PAYMENT: "SAGA_ACTIONS/PAYMENT/MAKE_PAYMENT",
    MAKE_BULK_PAYMENT: "SAGA_ACTIONS/PAYMENT/MAKE_BULK_PAYMENT",
  },
};

export * from "./site_statics";
export * from "./site_urls";
export * from "./functions";
export * from "./canvasUtils";
export * from "./card_utils";
