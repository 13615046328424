import React, { useEffect } from "react";
import CmsPage from "src/components/cms/cmsPage";
import Footer from "src/components/common/Footer";
import TopHeader from "src/components/common/TopHeader";

export default function CmsContainer() {
  return (
    <React.Fragment>
      <div className="outer-container">
        <TopHeader gameId={0} />
        <CmsPage />
        <Footer />
      </div>
    </React.Fragment>
  );
}
