import { call } from "redux-saga/effects";
import { API_URL } from "../_config";
import { CallApi } from "./api/callApi";

export function* getCmsContent(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CMS.GET_CMS + "/" + data.slug + (data.id ? "/" + data.id : ""));
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
