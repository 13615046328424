import { useDispatch } from "react-redux";
import { ACTIONS } from "src/_config";

export function useAppEventAction() {
  const dispatch = useDispatch();

  const setTemporaryData = (data: any) => {
    dispatch({
      type: ACTIONS.TEAM.SAVE_TEMPORARY_DATA,
      payload: data,
    });
  };
  const setAISelectList = (data: any) => {
    dispatch({
      type: ACTIONS.TEAM.SET_AI_SELECT_LIST,
      payload: data,
    });
  };
  const clearAIList = (data?: any) => {
    dispatch({
      type: ACTIONS.TEAM.CLEAR_AI,
      payload: data,
    });
  };
  const clearConstAIList = (data?: any) => {
    dispatch({
      type: ACTIONS.TEAM.CLEAR_CONST_AI,
      payload: data,
    });
  };
  const updateAISelectList = (data?: any) => {
    dispatch({
      type: ACTIONS.TEAM.UPDATE_CONST_LIST_AI,
      payload: data,
    });
  };
  const clearResetTeamForm = (data?: any) => {
    dispatch({
      type: ACTIONS.TEAM.CLEAR_RESET_TEAM,
      payload: data,
    });
  };
  const clearMyTeamList = (data?: any) => {
    dispatch({
      type: ACTIONS.TEAM.MY_TEAM_CLEAR_LIST,
      payload: data,
    });
  };
  const setIsSearchTeamPage = (data: any) => {
    dispatch({
      type: ACTIONS.TEAM.IS_SEARCH_TEAM_PAGE,
      payload: data,
    });
  };
  const clearGameList = (data?: any) => {
    dispatch({
      type: ACTIONS.GAME.CLEAR_GAME_LIST,
      payload: data,
    });
  };
  const IsTeamCreateProcess = (data?: any) => {
    dispatch({
      type: ACTIONS.COMMON.TEAM_CREATE_PROCESS_STATUS,
      payload: data,
    });
  };
  const clearScore = (data?: any) => {
    dispatch({
      type: ACTIONS.SCORE.CLEAR_SCORE,
      payload: data,
    });
  };
  const clearTeamData = (data?: any) => {
    dispatch({
      type: ACTIONS.TEAM.CLEAR_TEAMDATA,
      payload: data,
    });
  };
  const updateReferrerUrl = (data?: any) => {
    dispatch({
      type: ACTIONS.COMMON.REFFERER_URL,
      payload: data,
    });
  };
  const clearDraftTeamData = (data?: any) => {
    dispatch({
      type: ACTIONS.TEAM.CLEAR_DRAFT_TEAM_DATA,
      payload: data,
    });
  };
  const clearLoadedLeagueData = (data?: any) => {
    dispatch({
      type: ACTIONS.LEAGUE.CLEARLOADEDLEAGUE,
      payload: data,
    });
  };
  return {
    setTemporaryData,
    setAISelectList,
    clearAIList,
    clearConstAIList,
    updateAISelectList,
    clearResetTeamForm,
    clearMyTeamList,
    setIsSearchTeamPage,
    clearGameList,
    IsTeamCreateProcess,
    clearScore,
    clearTeamData,
    updateReferrerUrl,
    clearDraftTeamData,
    clearLoadedLeagueData
  };
}
