import React, { useEffect } from "react";
import SurveyPage from "src/components/cms/surveyPage";
import Footer from "src/components/common/Footer";
import TopHeader from "src/components/common/TopHeader";

export default function SurveyContainer() {
  return (
    <React.Fragment>
      <div className="outer-container">
        <TopHeader gameId={0} />
        <SurveyPage />
        <Footer />
      </div>
    </React.Fragment>
  );
}
