import { any } from "prop-types";
import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { ACTIONS } from "src/_config";
export interface EventReducer {
  getMenuList: any;
  settings: any;
  sponsor: any;
  blocksData: any;
}

const initialState: EventReducer = {
  getMenuList: null,
  settings: null,
  sponsor: null,
  blocksData: null,
};

const eventReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COMMON.MENU_LIST:
      return {
        ...state,
        getMenuList: action.payload.data,
      };
    case ACTIONS.COMMON.SETTINGS:
      return {
        ...state,
        settings: action.payload.data,
      };
    case ACTIONS.COMMON.SPONSOR:
      return {
        ...state,
        sponsor: action.payload.data,
      };

    case ACTIONS.COMMON.BLOCKS:
      return {
        ...state,
        blocksData: action.payload.data.blocks,
      };
    default:
      return state;
  }
};

export default eventReducer;
