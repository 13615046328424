import { combineReducers } from "redux";
import loaderReducer from "./common/loaderReducer";
import globalsReducer from "./common/globalsReducer";
import userReducer from "./user/userReducer";
import eventReducer from "./event/eventReducer";
import gameReducer from "./game/gameReducer";
import teamReducer from "./team/teamReducer";
import scoreReducer from "./score/scoreReducer";

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  event: eventReducer,
  game: gameReducer,
  team: teamReducer,
  scores: scoreReducer,
  global: globalsReducer,
});

export default rootReducer;
