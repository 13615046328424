import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { textTruncate } from "src/_config";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { confirmAlert } from "react-confirm-alert";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";

interface TeamListModalProps {
  onClose: (success: any) => void;
  shouldShow: boolean;
  leagueCode: string;
}

export default function TeamListModal({ onClose, shouldShow, leagueCode }: TeamListModalProps) {
  const eventApi = useEventApi();
  const [teamLeagueList, setTeamLeagueList] = useState<any>();
  const toast = useToaster();

  const getTeamList = () => {
    let params = { leagueCode };
    eventApi.callGetTeamLeagueList(
      params,
      (message: any, resp: any) => {
        setTeamLeagueList(resp);
      },
      (message: any) => { }
    );
  };

  useEffect(() => {
    getTeamList();
  }, []);

  return (
    <React.Fragment>
      <Modal
        // onClick={this.toggle}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // className={"insights-modal"}
        show={shouldShow}
      >
        <div className="cross-icon cross-icon-w" onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <Modal.Header className={"mod-head"}>
          {teamLeagueList && teamLeagueList.league_info ? (
            <h5 className="modal-title league-modal-title">
              {teamLeagueList.league_info.league_name} -{" "}
              <input
                readOnly
                type="text"
                value={teamLeagueList.league_info.league_code}
                className="copy-to-clipboard"
              />
              <span
                className="text-red copy-text-pan"
              //   onClick={(e) => copyText(e)}
              >
                <i className="fa fa-copy"></i> {teamLeagueList.league_info.league_code}
              </span>
            </h5>
          ) : null}
        </Modal.Header>
        <Modal.Body className={"p-3"}>
          <div className="payment-modal">
            <div className="addleague_modallist mt-0">
              <div className="overall-result-panel">
                <div className="team-list-modal">
                  <div className="team-list-title">
                    <h4>TEAMS</h4>
                    <p>Please find the Teams below</p>
                  </div>

                  {teamLeagueList && teamLeagueList.list.length > 0 ? (
                    teamLeagueList.list.map((k: any, j: any) => {
                      return (
                        <div className="team-list-links">
                          <ul>
                            <li>
                              {k.team_name} <span className="crtby">created by </span> {k.first_name} {k.last_name}
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <div>No Team added yet!</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
