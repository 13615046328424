import { SAGA_ACTIONS } from "src/_config";
import { LoginReq, LoginWithOtpReq, UpdateUserProfile } from "src/_common/interfaces/ApiReqRes";
import { useApiCall } from "../common/appApiCallHook";

export function useUserApi() {
  const callApi = useApiCall();

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  };

  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  };

  const loginWithOtp = (data: LoginWithOtpReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, data, onSuccess, onError);
  };

  const loginWithUsername = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN_WITH_USERNAME, data, onSuccess, onError);
  };

  const updateUserProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_PROFILE, data, onSuccess, onError);
  };

  const getMe = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DETAILS, null, onSuccess, onError);
  };

  const logout = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, null, onSuccess, onError);
  };

  const deleteUser = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DELETE_USER, data, onSuccess, onError);
  };

  return {
    callLogin: login,
    callLoginWithOtp: loginWithOtp,
    callUpdateUserProfile: updateUserProfile,
    callLogout: logout,
    callGetMe: getMe,
    callWithUsername: loginWithUsername,
    callForgotPassword: forgotPassword,
    callDeleteUser: deleteUser,
  };
}
