import { any } from "prop-types";
import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { ACTIONS } from "src/_config";
export interface GameReducer {
  gameList: any;
}

const initialState: GameReducer = {
  gameList: null,
};

const gameReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.GAME.GET_GAME_LIST:
      return {
        ...state,
        gameList: action.payload.data,
      };
    case ACTIONS.GAME.CLEAR_GAME_LIST:
      return {
        ...state,
        gameList: null,
      };
    default:
      return state;
  }
};

export default gameReducer;
