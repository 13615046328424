import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppUserAction() {

  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type: ACTIONS.USER.LOGOUT,
    })
  }



  // const updateDisplayName = (respData: any) => {
  //   dispatch({
  //     type: ACTIONS.USER.UPDATE_DISPLAY_NAME,
  //     payload: respData
  //   })
  // }


  return {
    logout,
    // socketInstanceContainer,
    // updateDisplayName,
  }
}