import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { User } from "../../interfaces/models/user";
import { StateExtended } from "../../interfaces/StateExtended";

export function useAppUserDetailsSelector() {
  const user: User | null = useSelector((state: StateExtended) => state.user.user);

  return user;
}

export function useAppUserAuthSelector() {
  const isAuth: boolean = useSelector((state: StateExtended) => state.user.isAuthenticated);

  return isAuth;
}
