export const CRYPTO_SECRET_KEY = "rOdEo_ApP";
export const PER_PAGE_LIST = 12;

export const DOB_MONTHS = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const YES_NO_RADIO = [
  {
    label: "No",
    value: "no",
  },
  {
    label: "Yes",
    value: "yes",
  },
];

export const GENDER_RADIO = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const PROFILE_IMAGE_ASPECT_RATIO = 1;

export const CUSTOM_MESSAGE = {
  SEND_OTP: "6 digit OTP has been send successfully",
  RESENT_OTP: "OTP Resend successfully.",
};
