import { toast } from 'react-toastify'
export function useToaster() {
  const show = (message: string) => {
    toast(message, { theme: "colored" })
  }
  const error = (message: string) => {
    toast.error(message, { theme: "colored" })
  }
  const success = (message: string) => {
    toast.success(message, { theme: "colored" })
  }
  const warning = (message: string) => {
    toast.warning(message, { theme: "colored" })
  }
  return { error, show, success, warning }
}