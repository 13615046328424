import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL } from "../_config";
import { CallApi } from "./api/callApi";

export function* getGameList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      data.game_id
        ? API_URL.GAME.GET_GAME_LIST + data.type + "/" + data.game_id
        : API_URL.GAME.GET_GAME_LIST + data.type
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.GAME.GET_GAME_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* gameEntryOpenNotification(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.GAME.ENTRY_OPEN_NOTIFICATION, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* upcomingGames(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.GAME.UPCOMING_GAMES, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getGameDetailsById(action: any): any {
  try {
    const game_id = action.payload;
    const resp = yield call(CallApi.GET, API_URL.GAME.GAMEDETAILSBYID + game_id);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* checkGameStatus(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.GAME.CHECKGAMESTATUS + data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* checkTranOffCode(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.GAME.CHECKTRANOFFCODE, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
