import React, { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
export default function CmsPage() {
  const [voteSurvey, setVoteSurvey] = useState<boolean>(false);
  const hideplan = {
    display: "none",
  };
  const showplan = {
    display: "block",
  };

  const ClickVote = () => {
    $("html, body").animate({
      scrollTop: $("#scroll-pos").offset().top,
    });
    setVoteSurvey(true)
  }
  return (
    <React.Fragment>
      <div className="main-container">
        <div className="vote-poll-wrapper">
          <div className="vote-poll-header">
            <div className="container">
              <ul style={hideplan}>
                <li><strong>Aug 2 - Aug 6</strong></li>
                <li>Dodge City, KS</li>
              </ul>
              <h1>Vote for which one you will pick!</h1>
              <h3 style={hideplan}>Choose our 2024 prize!</h3>
            </div>
          </div>
          <div className="vote-poll-inner-wrapper">
            <div className="container">
              <div className="vote-poll-title">
                <h2 style={hideplan}>Vote for which one you will pick!</h2>
                <p style={hideplan}>Choose our 2024 prize! Will it be the AWD Cyber Truck or the Ram 3500 with Four Horse Trailer? Cast your vote and help us decide!</p>
              </div>

              <div className="vote-poll-banner">
                <img src="/images/PFR-POLL-BANNER-2.png" alt="" />
                <div className="vote-poll-vs-text" style={hideplan}>
                  <h2>VS</h2>
                  <p>Choose 1</p>
                </div>
              </div>
              <div className="text-center mb-2">
                <a className="red-lg-btn linkcls" onClick={(e) => ClickVote()}>Click here to vote <i className="arrow-next-w"></i></a>
              </div>
              <div className="vote-poll-video-row">
                <div className="vote-poll-video-block">
                  <div className="vote-poll-video-title">
                    <h4>2024 Foundation AWD Cyber Truck</h4>
                    <p style={hideplan}>Win Cyber Truck by comleting the survey.</p>
                  </div>
                  <div className="vote-poll-video-wrapper">
                    <div className="vote-poll-video">
                      <iframe allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameBorder="0" src="https://cdn.jwplayer.com/players/FhAygm3J-vyZACtXE.html" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="Pro Fantasy Rodeo"></iframe>
                    </div>
                  </div>
                </div>
                <div className="vote-poll-video-block">
                  <div className="vote-poll-video-title">
                    <h4>Ram 3500 and 2024 Elite Four Horse Trailer</h4>
                    <p style={hideplan}>Win RAM 3500 by comleting the survey.</p>
                  </div>
                  <div className="vote-poll-video-wrapper">
                    <div className="vote-poll-video">
                      <iframe allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameBorder="0" src="https://cdn.jwplayer.com/players/W7Ax0Ph4-vyZACtXE.html" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="Pro Fantasy Rodeo"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mb-2">
                <Link to={"/game/2024-nfr-game"} className="red-lg-btn linkcls">PLAY NOW</Link>
              </div>
              <div className="vote-poll-title">
                <h2>More Videos</h2>
              </div>
              <div className="vote-poll-video-row">
                <div className="vote-poll-video-block">
                  <div className="vote-poll-video-title">
                    <h4>Anthony Lucia is locked and loaded!</h4>
                    <p style={hideplan}>Win Cyber Truck by comleting the survey.</p>
                  </div>
                  <div className="vote-poll-video-wrapper">
                    <div className="vote-poll-video">
                      <iframe allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameBorder="0" src="https://cdn.jwplayer.com/players/I3UYCJwP-vyZACtXE.html" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="Pro Fantasy Rodeo"></iframe>
                    </div>
                  </div>
                </div>
                <div className="vote-poll-video-block">
                  <div className="vote-poll-video-title">
                    <h4> Win KC's Cybertruck</h4>
                    <p style={hideplan}>Win Cyber Truck by comleting the survey.</p>
                  </div>
                  <div className="vote-poll-video-wrapper">
                    <div className="vote-poll-video">
                      <iframe allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameBorder="0" src="https://cdn.jwplayer.com/players/xRAW9VNt-vyZACtXE.html" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} title="Pro Fantasy Rodeo"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="scroll-pos"></div>
      <div style={voteSurvey ? showplan : hideplan}>
        <iframe frameBorder={0} width="100%" height="800" src="landing.html" />
      </div>
    </React.Fragment>
  );
}
