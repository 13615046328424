import { type } from "os";
import { ActionExtended } from "../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../_config";

export interface GlobalReducer {
  teamCreateProcessStatus: boolean;
  seo_list: any;
  referrer_url: any;
}

const initialState: GlobalReducer = {
  teamCreateProcessStatus: false,
  seo_list: null,
  referrer_url: null,
};

const globalsReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COMMON.TEAM_CREATE_PROCESS_STATUS:
      return {
        ...state,
        teamCreateProcessStatus: action.payload,
      };
    case ACTIONS.COMMON.SEO_LIST:
      return {
        ...state,
        seo_list: action.payload.data,
      };
    case ACTIONS.COMMON.REFFERER_URL:
      return {
        ...state,
        referrer_url: action.payload,
      };
    default:
      return state;
  }
};

export default globalsReducer;
