import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { User } from "../../interfaces/models/user";
import { StateExtended } from "../../interfaces/StateExtended";

export function useGameScoreSelector() {
  const scores = useSelector((state: StateExtended) => state.scores.scores);
  return scores;
}

export function useRodeoResultsSelector() {
  const rodeoResults = useSelector((state: StateExtended) => state.scores.rodeoResults);
  return rodeoResults;
}
export function useRoundScoreSelector() {
  const roundScore = useSelector((state: StateExtended) => state.scores.roundScore);
  return roundScore;
}
export function useCurcuitResultsSelector() {
  const roundScore = useSelector((state: StateExtended) => state.scores.curcuitScore);
  return roundScore;
}
export function useLatestStandingsSelector() {
  const standingsData = useSelector((state: StateExtended) => state.scores.standingsData);
  return standingsData;
}
export function useLeagueOverallScoreSelector() {
  const leagueScores = useSelector((state: StateExtended) => state.scores.leagueScores);
  return leagueScores;
}

export function useLeagueRoundScoreSelector() {
  const roundLeagueScore = useSelector((state: StateExtended) => state.scores.roundLeagueScore);
  return roundLeagueScore;
}
export function usePrizeOverallScoreSelector() {
  const prizeScores = useSelector((state: StateExtended) => state.scores.prizeScores);
  return prizeScores;
}

export function usePrizeRoundScoreSelector() {
  const prizeRoundscores = useSelector((state: StateExtended) => state.scores.prizeRoundscores);
  return prizeRoundscores;
}
export function useTopHeaderScoreSelector() {
  const topGameTabData = useSelector((state: StateExtended) => state.scores.topGameTabData)
  return topGameTabData;
}