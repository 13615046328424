import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { StateExtended } from "../../interfaces/StateExtended";

export function useLoadTempTeamDataSelector() {
  const tempTeamList = useSelector((state: StateExtended) => state.team.tempTeamDraftList);
  return tempTeamList;
}

export function useEventsSelector() {
  const eventsList = useSelector((state: StateExtended) => state.team.eventList);
  return eventsList;
}

export function useInsuranceSelector() {
  const insuranceList = useSelector((state: StateExtended) => state.team.insuranceList);
  return insuranceList;
}

export function usePrizeSelector() {
  const prizeList = useSelector((state: StateExtended) => state.team.prizeList);
  return prizeList;
}

export function useFrontLeagueSelector() {
  const silverLeague = useSelector((state: StateExtended) => state.team.silverLeague);
  return silverLeague;
}

export function useContestantAIListSelector() {
  const contestantAIList = useSelector((state: StateExtended) => state.team.contestantAIList);
  return contestantAIList;
}
export function useAISelectListSelector() {
  const AISelectList = useSelector((state: StateExtended) => state.team.AISelectList);
  return AISelectList;
}
export function useAISEARCHCONSTListSelector() {
  const constList = useSelector((state: StateExtended) => state.team.constList);
  return constList;
}
export function useMyTeamListSelector() {
  const teamList = useSelector((state: StateExtended) => state.team.teamList);
  return teamList;
}
export function useMyLeagueListSelector() {
  const leagueList = useSelector((state: StateExtended) => state.team.leagueList);
  return leagueList;
}
export function useCurrentGameListSelector() {
  const curGameList = useSelector((state: StateExtended) => state.team.curGameList);
  return curGameList;
}

export function useIsSearchTeamPageSelector() {
  const isSearchTeamPage = useSelector((state: StateExtended) => state.team.isSearchTeamPage);
  return isSearchTeamPage;
}

export function useCurrentTeamDetailsSelector() {
  const currentTeamDetails = useSelector((state: StateExtended) => state.team.loadedTeam);
  return currentTeamDetails;
}
export function usePaymentPlansSelector() {
  const paymentPlans = useSelector((state: StateExtended) => state.team.paymentPlans);
  return paymentPlans;
}
export function usePerformanceSelector() {
  const performanceList = useSelector((state: StateExtended) => state.team.performanceList);
  return performanceList;
}
export function useCowboyInsurance() {
  const cowboyInsurance = useSelector((state: StateExtended) => state.team.cowboyInsurance);
  return cowboyInsurance;
}
export function useCurrentTeamRankPerformanceSelector() {
  const rankdetails = useSelector((state: StateExtended) => state.team.rankdetails);
  return rankdetails;
}
export function useCartItems() {
  const cartItems = useSelector((state: StateExtended) => state.team.cartItems);
  return cartItems;
}
export function useLoadedLeague() {
  const loadedLeague = useSelector((state: StateExtended) => state.team.loadedLeague);
  return loadedLeague;
}