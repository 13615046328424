import { call, put } from "redux-saga/effects";
import { API_URL, ACTIONS } from "../_config";
import { CallApi } from "./api/callApi";

export function* getScoreDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.GET_SCORE_DETAILS + data.gameId + "/" + data.teamId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.GET_SCORE_DETAILS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getPickBbrScoreDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.GET_PICK_BBR_SCORE_DETAILS + data.gameId + "/" + data.teamId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.GET_SCORE_DETAILS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getRankDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.GET_RANK_DETAILS + data.gameId + "/" + data.teamId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.GET_RANK_DETAILS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getScore(action: any): any {
  try {
    const gameSlug = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.GET_GAME_SCORE + gameSlug);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.GET_GAME_SCORE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getRodeoResults(action: any): any {
  try {
    const gameSlug = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.GET_RODEO_RESULTS + gameSlug);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.GET_RODEO_RESULTS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getRoundWiseResults(action: any): any {
  try {
    const gameSlug = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.ROUNDWISERESULTS + gameSlug);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.ROUNDWISERESULTS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getCurcuitResults(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.CURCUITRESULTS + data.gameSlug + "/" + data.curcuitId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.CURCUITRESULTS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getLatestStandings(action: any): any {
  try {
    // const gameSlug = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.LATESTSTANDINGS);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.LATESTSTANDINGS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getLeagueOverallScoreResults(action: any): any {
  try {
    const leagueID = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.LEAGUEOVERALLSCORE + leagueID);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.LEAGUEOVERALLSCORE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getLeagueRoundScoreResults(action: any): any {
  try {
    const leagueID = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.LEAGUEROUNDSCORE + leagueID);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.LEAGUEROUNDSCORE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getPrizeOverallScoreResults(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.PRIZEOVERALLSCORE + data.gameSlug + "/" + data.prizeId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.PRIZEOVERALLSCORE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getPrizeRoundScoreResults(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.PRIZEROUNDSCORE + data.gameSlug + "/" + data.prizeId);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.PRIZEROUNDSCORE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getTopHeaderScore(action: any): any {
  try {
    // const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SCORE.TOP_HEADER_SCORE_BAR);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCORE.TOPHEADERSCORE,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
