import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { textTruncate } from "src/_config";
import { useEventApi } from "src/_common/hooks/actions/event/appEventApiHook";
import { confirmAlert } from "react-confirm-alert";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";

interface CardsModalProps {
  onClose: (success: any) => void;
  shouldShow: boolean;
}

export default function CardsModal({ onClose, shouldShow }: CardsModalProps) {
  const eventApi = useEventApi();
  const [cardList, setCardList] = useState<any>();
  const [deletePopup, setDeletePopup] = useState(false);
  const [defaultPopup, setDefaultPopup] = useState(false);
  const [cardId, setCardId] = useState<number>(0);
  const toast = useToaster();

  const openCardDetails = () => {
    let params = {};
    eventApi.callGetCardList(
      params,
      (message: any, resp: any) => {
        setCardList(resp);
      },
      (message: any) => { }
    );
  };

  const deleteCard = (card_id: any) => {
    let params = { card_id };
    eventApi.callDeleteCard(
      params,
      (message: any, resp: any) => {
        let x = cardList?.data?.filter((c: any) => c.id != card_id);
        setCardList({ ...cardList, data: x });
      },
      (message: any) => {
        toast.error(message);
      }
    );
  };

  const setDefaultCard = (card_id: any) => {
    eventApi.callSetDefaultCard(
      { card_id },
      (message: any, resp: any) => {
        eventApi.callGetCardList(
          {},
          (message: any, resp: any) => {
            setCardList(resp);
          },
          (message: any) => { }
        );
      },
      (message: any) => {
        toast.error(message);
      }
    );
  };

  useEffect(() => {
    openCardDetails();
  }, []);

  return (
    <React.Fragment>
      <Modal
        // onClick={this.toggle}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={"insights-modal"}
        show={shouldShow}
      >
        <div className="cross-icon" onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <Modal.Header className={"mod-head"}>
          <h5 className="modal-title" id="staticBackdropLabel">
            Your Cards ({cardList?.data?.length})
          </h5>
        </Modal.Header>
        <Modal.Body className={"m-18"}>
          <div className={deletePopup || defaultPopup ? "opa2" : "position-relative"}>
            {cardList?.data?.length ? (
              <div>
                <p className="text-red d-inline-flex gap-2 align-items-center">
                  <i className="alert-icon popup-info-icon"></i> Here are all of your saved cards, if any card got
                  expired/invalid please delete from here
                </p>
                <div className="table-responsive maxhei  cc-list-wrap">
                  <table className="table table-hover table-bordered cc-list">
                    <tr>
                      <th>Card Number</th>
                      <th>Card Holder Name</th>
                      <th>Actions</th>
                    </tr>
                    {cardList?.data?.map((v: any, i: any) => {
                      return (
                        <tr key={i}>
                          <td>{v.card_number}</td>
                          <td>{v.cardholder_name}</td>
                          <td>
                            <i
                              className="fa fa-trash-o"
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => {
                                setCardId(v.id)
                                setDeletePopup(true)
                              }}
                            />
                            {v?.is_default == 1 ? (
                              <i className="fa fa-check" style={{ color: "lime", fontSize: "20px" }} />
                            ) : (
                              <i
                                className="fa fa-check-square-o"
                                style={{ color: "#939393", fontSize: "20px", cursor: "pointer" }}
                                onClick={() => {
                                  setCardId(v.id)
                                  setDefaultPopup(true)
                                }
                                }
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            ) : (
              <div className="alert alert-danger gap-2 d-inline-flex w-100 align-items-center">
                <i className="alert-icon  popup-info-icon"></i>You don't have any saved card!
              </div>
            )}
          </div>

          <div className={deletePopup ? "confirm-delete-box position-absolute" : "d-none"}>
            <h1>Are you sure you want to delete your Card?</h1>
            <button className="dark-button" onClick={() => setDeletePopup(false)}>No</button>
            <button className="red-button" onClick={() => {
              deleteCard(cardId)
              setDeletePopup(false)
            }}>Yes</button>
          </div>
          <div className={defaultPopup ? "confirm-delete-box position-absolute" : "d-none"}>
            <h1>Are you sure to set the card as default?</h1>
            <button className="dark-button" onClick={() => setDefaultPopup(false)}>No</button>
            <button className="red-button" onClick={() => {
              setDefaultCard(cardId)
              setDefaultPopup(false)
            }}>Yes</button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
