import React, { useState, useEffect } from "react";
import { useSEOSelector } from "src/_common/hooks/selectors/eventSelector";
import MetaDecorator from "./MetaDecorator";
import { useLocation } from 'react-router-dom'
import { PAGE_TITLE } from "src/_config";
export default function SEOHook() {
  const [pageTitle, setPageTitle] = useState<any>();
  const [pageDesc, setPageDesc] = useState<any>();
  const [pageKeywords, setPageKeywords] = useState<any>();
  const location = useLocation();
  const SEOList = useSEOSelector();
  useEffect(() => {

    if (location?.pathname && SEOList) {
      let pnameArr = location.pathname
      let pname = pnameArr.split("/")
      if (pname[1] == '') {
        let findIndex = SEOList.findIndex((x: any) => x.page_title == 'landing');
        if (findIndex > -1) {
          setPageTitle(PAGE_TITLE + SEOList[findIndex].meta_title)
          setPageDesc(SEOList[findIndex].meta_description)
          setPageKeywords(SEOList[findIndex].meta_keyword)
        }
      } else if (pname[1] == 'cms') {
        setPageTitle('')
        setPageDesc('')
        setPageKeywords('')
      } else {
        let findIndex = SEOList.findIndex((x: any) => x.page_title == pname[1]);
        if (findIndex > -1) {
          setPageTitle(PAGE_TITLE + SEOList[findIndex].meta_title)
          setPageDesc(SEOList[findIndex].meta_description)
          setPageKeywords(SEOList[findIndex].meta_keyword)
        }
      }
    }
  }, [SEOList]);


  return (<MetaDecorator title={pageTitle} description={pageDesc} keywords={pageKeywords} />
  );
}
