import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Redirect } from 'react-router'
import { URLS } from '../../_config'
import Layout from '../layout/Layout'
import { useAuthStatus } from '../hooks/auth/authHook'
import { useAppUserDetailsSelector } from '../hooks/selectors/userSelector'
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
const requireAuth = (Component: React.ComponentType, role: number = 0) => {
  function AuthHoc(props: any) {
    const isAuth = useAuthStatus()
    const user = useAppUserDetailsSelector()
    const location = useLocation();
    //Save value to Redux
    const eventAction = useAppEventAction();
    useEffect(() => {
      eventAction.updateReferrerUrl(location.pathname)
    }, [location]);
    /**
     * TODO: add role bases checking here
     */
    return (
      // isAuth && user && user.user_type === role ? <Layout>
      isAuth && user ? <Layout>
        <Component {...props} />
      </Layout> : <Redirect to={URLS.LOGIN} />
    )
  }

  return AuthHoc
}
export default requireAuth
