import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from '../layout/Layout'
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";

const reqLayout = (Component: React.ComponentType) => {
  function LayoutHoc(props: any) {
    const location = useLocation();
    //Save value to Redux
    const eventAction = useAppEventAction();
    useEffect(() => {
      eventAction.updateReferrerUrl(location.pathname)
    }, [location]);
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    )
  }

  return LayoutHoc
}
export default reqLayout
