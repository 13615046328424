import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface MetaDecoratorProps {
  title: string,
  description: string,
  keywords: string,
  canonical?: string
};

const MetaDecorator = ({ title, description, keywords, canonical }: MetaDecoratorProps) => (

  < HelmetProvider >
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords}></meta>
      {canonical ? <link rel="canonical" href={canonical} /> : null}
    </Helmet>
  </HelmetProvider >
);

export default MetaDecorator;
