import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { URLS } from "src/_config";
import { useMenuListSelector, useGlobalTeamStatusSelector } from "src/_common/hooks/selectors/eventSelector";
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import { useAppUserAuthSelector } from "src/_common/hooks/selectors/userSelector";
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert';
import CartModal from "../modals/cartModal";
interface HeaderProps {
  gameId?: Number;
}

function Footer() {
  const hideplan = {
    display: "none",
  };
  const showplan = {
    display: "block",
  };
  const menuItems = useMenuListSelector();
  const teamStatus = useGlobalTeamStatusSelector();
  const eventAction = useAppEventAction();
  const history = useHistory();
  const location = useLocation();
  const isAuth = useAppUserAuthSelector();
  const [bottomFixedsec, setBottomFixedsec] = useState<any>(false);
  const [pathName, setPathName] = useState<any>("");
  const [showCartModal, setShowCartModal] = useState<boolean>(false);

  useEffect(() => {
    if (navigator.userAgent.match(/(iPhone|iPad)/i) && navigator.userAgent.match(/WebKit/i)) {
      if (localStorage.getItem("is_add_screen_popup") == null) setBottomFixedsec(true);
      else setBottomFixedsec(false);
    } else {
      setBottomFixedsec(false);
    }
    history.listen((location: any, action: any) => {
      setPathName(location.pathname);
    });
    setPathName(location.pathname);
  }, [history, location]);

  const deletePopup = () => {
    setBottomFixedsec(false);
    localStorage.setItem("is_add_screen_popup", "1");
  };

  const goToPage = (url: any) => {
    if (teamStatus) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Do you want to leave Create Team Page?</h1>
              <button className="dark-button" onClick={() => {
                onClose()
              }}>No</button>
              <button className="red-button" onClick={() => {
                history.push(url);
                eventAction.IsTeamCreateProcess(false)
                onClose()
              }}>Yes</button>
            </div>
          )
        }
      })
    } else {
      history.push(url);
    }

  };

  const openCartModal = () => {
    setShowCartModal(true);
  };

  const closeCartModal = () => {
    setShowCartModal(false);
  };

  return (
    <React.Fragment>
      <div className="mob-copy-text">
        <p>{moment(new Date()).format("YYYY")} © Copyright Pro Fantasy Rodeo. All rights reserved.</p>
      </div>
      {pathName != "/" && (
        <div className="sports-logo-panel">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <img src="/images/TR-gaming-new.png" alt="" />
            <span style={hideplan}>SPORTS</span>
          </div>
        </div>
      )}
      <div className="footer footer-desktop mt-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="footer-logo" onClick={(e) => history.push(URLS.LANDING)} style={{ cursor: "pointer" }}>
                <img src="/images/footer-logo.png" alt="" />
              </div>
              <p className="copyright-text">© {moment(new Date()).format("YYYY")} All rights reserved.</p>
              <div className="social-icons">
                <a href="https://www.facebook.com/Profantasyrodeo1" target="_blank">
                  <img src="/images/fb.svg" alt="" />
                </a>
                <a href="#" target="_blank" style={hideplan}>
                  <img src="/images/tw.svg" alt="" />
                </a>
                <a href="https://www.instagram.com/profantasyrodeo/" target="_blank">
                  <img src="/images/insta.svg" alt="" />
                </a>
              </div>
            </div>
            {menuItems?.footer &&
              menuItems.footer.map((v: any, z: number) => {
                return (
                  <div className="col-sm-3" key={z}>
                    <h3 className="footer-heading">{v.group_name}</h3>
                    <ul className="footerlinks">
                      {v.pages.map((p: any, j: number) => {
                        return (
                          <li key={j}>
                            <a className="linkcls" onClick={(e) => goToPage(p.url)}>
                              {p.menu}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="footer-mob">
        <div className="footer-mob-top">
          <div className="accordion" id="menuitems">
            {menuItems?.footer &&
              menuItems.footer.map((v: any, z: number) => {
                return (
                  <div className="accordion-item" key={z}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapseOne" + z}
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        {v.group_name}
                      </button>
                    </h2>
                    <div id={"collapseOne" + z} className="accordion-collapse collapse" data-bs-parent="#menuitems">
                      <div className="accordion-body">
                        <ul className="footerlinks">
                          {v.pages.map((p: any, j: number) => {
                            return (
                              <li key={j}>
                                <a className="linkcls" onClick={(e) => goToPage(p.url)}>
                                  {p.menu}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/Profantasyrodeo1" target="_blank">
              <img src="/images/fb.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/profantasyrodeo/" target="_blank">
              <img src="/images/insta.svg" alt="" />
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-mob-logo">
            <img src="/images/footer-mob-sm.png" alt="" />
          </div>
        </div>
      </div>

      {isAuth && menuItems?.my_cart_items > 0 ? <div className="floating-cart" onClick={(e) => openCartModal()}>
        <span className="cart-badge">{menuItems?.my_cart_items}</span>
        <i className="fa fa-users cart-grp-icn"></i>
        <div className="cart-hover-text">View cart</div>
      </div> : null}

      <div className="bottom_fixedsec" style={bottomFixedsec ? showplan : hideplan}>
        <div className="mid-sec">
          <h2>
            To add this Instant app to the home screen: tap <span className="share-x-icon"></span> and then Add to Home
            Screen.
          </h2>
        </div>
        <div className="icon_div_right">
          <a className="linkcls" onClick={deletePopup}>
            <i className="fa fa-times-circle"></i>
          </a>{" "}
        </div>
      </div>
      {showCartModal && <CartModal shouldShow={showCartModal} onClose={closeCartModal} />}
    </React.Fragment>
  );
}

export default Footer;
