import React from "react";

const LazyRodeoEvent = React.lazy(() => import("./riders"));

const RidersContainer = (props: Record<string, any>) => (
  <React.Suspense
    fallback={
      <div className="transition-loader">
        <img src="/images/bull-loader.gif" />
      </div>
    }
  >
    <LazyRodeoEvent {...props} />
  </React.Suspense>
);

export default RidersContainer;
