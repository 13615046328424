import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";
import { Redirect } from 'react-router'
import { URLS } from '../../_config'
import Layout from '../layout/Layout'
import { useAuthStatus } from '../hooks/auth/authHook'
// import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
const requireNoAuth = (Component: React.ComponentType) => {
  function NoAuthHoc(props: any) {
    const isAuth = useAuthStatus()
    // const location = useLocation();
    // //Save value to Redux
    // const eventAction = useAppEventAction();
    // useEffect(() => {
    //   eventAction.updateReferrerUrl(location.pathname)
    // }, [location]);
    return (
      isAuth ? <Redirect to={URLS.LANDING} /> : <Layout>
        <Component {...props} />
      </Layout>
    )
  }

  return NoAuthHoc
}
export default requireNoAuth
